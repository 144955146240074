/****************************************************************
/ default grid of projects
/****************************************************************/


.default-grid{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.85em;

    @media #{$media-mobile} {
        display: block!important;
        margin-bottom: 4rem;
    }

    .grid-item{

        img{
            width: 100%;
            height: auto;
        }

        .year{
            font-family: $numberplate;
            font-size: $size-fixed-20;
            margin-top: 0.8rem;
            line-height: 1.5;

            @media #{$media-mobile} {
                margin-top: 0.6em;
            }
            
        }
        
        h2{
            text-transform: none;
            font-family: $abc;
            font-size: $size-70-h2;
            margin-bottom: 1.5em;

            @media #{$media-mobile} {
                margin-bottom: 0.4em;
            }
        }
    }
}

.default-grid-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

/****************************************************************
/ grid with background
/****************************************************************/


.background-grid{

    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: $pad-small;

    @media #{$media-mobile} {
        display: block;
        margin-bottom: 4rem;
    }

}

.background-grid-item{

    .bg {

        position: relative;
        background-color: #232323;

        .cover {
            width: 69%;
            height: 72.5%; // 483/666

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .bg:before{
        content: "";
        display: block;
        padding-top: 126%;
    }

    .main-image {
        // padding-top: 126%;
        // background-position: center;
        // background-repeat: no-repeat;
        // background-size: cover;
        width: 100%;

        // @media #{$media-mobile} {
        //     width: 100%;
        // }
    }

    .text{
        margin-top: 1.4rem;
        margin-bottom: 5.6rem;

        @media #{$media-mobile} {
            margin-bottom: 1.7rem;
        }

        .authors{
            margin-top: 0.8rem;
            text-transform: uppercase;
        }
    }
}

/****************************************************************
/ horizontal grid item (lists of exhibitions, nfts...)
/****************************************************************/


.horizontal-item{

    padding-top: 1.7rem;
    padding-bottom: 2.5rem;

    border-width: 0 0 $border-3 0;
    border-color: black;
    border-style: solid;

    display: grid;
    grid-template-columns: 31vw 1fr;
    gap: 1.7em;

    @media #{$media-tablet} {
        border-width: 0 0 2px 0;
    }

    @media #{$media-mobile} {
        display: block!important;
    }

    .image{
        height: 27.1vw;
        min-height: 458px;

        @media #{$media-mobile} {
            min-height: 417px;
        }
    }

    .text {

        height: fit-content;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        @media #{$media-mobile} {
            position: static;
            transform: none;
        }

        h2{
            font-size: $size-65-38;
            line-height: 1; 
            letter-spacing: -0.04em;
            margin-bottom: 0.14em;

            @media #{$media-mobile} {
                letter-spacing: -0.02em;
                margin-top: $pad;
            }
        }

        h3{
            font-size: $size-20f;
            line-height: 1.354; 
            margin-bottom: 1em;
        }

        .abstract{
            font-size: $size-fixed-20;
            line-height: 1.25;
            letter-spacing: -0.04em;

            p{
                margin-top: 0.7em;
            }

            p:first-of-type{
                margin-top: 1.5em;
            }
        }

        .buttons{
            margin-top: 1.8rem;

            .button{
                min-width: 105px;
                text-align: center;
            }
        }
    }
}

.horizontal-item:last-of-type {
    padding-bottom: 0;
    border-style: none;
}
