.template-homepage{

    transition: color 0.5s ease, filter 0.5s ease;

    padding-top: 0; 
    padding-bottom: 0; 

    .flex-container{

        display: flex;
        flex-direction: column;
        
        height: 100vh; /* This is for browsers that don't support custom properties */
        height: calc(var(--vh, 1vh) * 100);
        padding-top: clamp(14px, 2.5vw, 30px);
        padding-bottom: clamp(14px, 2.5vw, 30px);

        z-index: 1;

        #homepage-logo {
            @media #{$media-tablet} {
                order: 1;
                padding-bottom: 14px;
                border-width: 0 0 3px 0;
                border-color: black;
                border-style: solid;
            }

            @media #{$media-mobile} {
                border-width: 0 0 2px 0;
            }
        }

        .homepage-selectors {
            @media #{$media-tablet} {
                order: 4;
            }
        }
    
        .text-container {
    
            flex-grow: 1;
            position: relative;
            overflow: hidden;
            z-index: -1;

            @media #{$media-tablet} {
                order: 3;
            }
    
            div {

                opacity: 0;

                font-size: $size-homepage-text;
                letter-spacing: -0.04em;
                line-height: 1.17;
                text-align: center;
                margin: 0 auto;   
    
                width: 90%;

                @media #{$media-tablet} {
                    width: 100%;
                    font-size: 45px;
                }

                @media #{$media-mobile} {
                    font-size: 31px;
                }

                @media #{$media-mobile-H} {
                    font-size: 25px;
                }
    
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
    
            #home-text-initial {
                display: block;
                // width: 66%; 
            }
    
            #home-text-art, #home-text-science {
                display: none;
            }
        }
    
        .search{
            margin-top: $pad;
            height: $homepage-selectors-height;
            font-size: $size-70-h1;

            @media #{$media-tablet} {
                order: 2;
                margin-top: 0;
                font-size: 50px;
                border-radius: 0;
                border-width: 0 0 3px 0;
            }

            @media #{$media-mobile} {
                border-width: 0 0 2px 0;
                font-size: 30px;
                height: 55px;
            }
        }
    }

    .home-image{
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        // opacity: 0.4;

        z-index: -2;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media #{$media-tablet} {
            display: block;
        }
    }

    #image-art {
        @media #{$media-tablet} {
            left: 50%;
            right: 0;
            width: unset;
        }
    }
    #image-science {
        @media #{$media-tablet} {
            right: 50%;
            width: unset;
        }
    }



    #backdrop{
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        
        opacity: 0;

        background-color:black;

        z-index: -3;

        @media #{$media-tablet} {
            display: block;
        }
    }
}

.template-homepage.active {
    color: white;
    border-color: white;

    #homepage-logo{
        filter: invert(100%);
    }

    #backdrop{
        display: block;
        opacity: 1;
    }
}