.template-projects{
    padding-bottom: 2.5em;


    @media #{$media-mobile} {

        .default-grid{
            margin-bottom: 0;
        }

        .default-grid:last-of-type{
            margin-bottom: 4rem;
        }
    }

    

}