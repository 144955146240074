.template-book-common {

    padding: $pad;

    h1{
        font-size: $size-65;
        
        @media #{$media-mobile} {
            font-size: $size-40-h2;
        }
    }

    .image{

        margin: 3rem 0;
        height: 40vh;

        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;

        .cover-wrapper{
            margin: 0 auto;
            max-height: 40vh;
            .cover {
                // display: block;
                // margin: 0 auto;
                // width: auto;
                max-height: 40vh;
            }
        }

        .nav-arrow {
            display: block;
            width: 2.6em;
            justify-self: center;
            margin: $pad;

            img {
                width: inherit;
            }
        }
    }

    .text {
        margin-bottom: 2em;

        div:first-of-type {
            margin-top: 0;
        }
    }

    .links{

        margin-top: 3em;

        h5 {
            margin-bottom: 0.8em;
        }

        li{
            a {
                text-decoration: underline;
            }

            a:hover{
                text-decoration: none;
            }

            img{
                width: 1em;
                margin-left: 0.8em;
            }
        }
    }
}

.template-art-detail.template-book-common .swiper {

    margin: 1.5em 1.4rem;

    .swiper-wrapper {
        height: 60vh;
        .swiper-slide {
            grid-template-rows: 1fr 5rem;

            .image{
                height: unset;
            }
        }
    }

}

