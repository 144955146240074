.template-exhibitions{

    margin-top: -1.7rem;

    @media #{$media-mobile} {
        margin-bottom: 3rem;
    }

    .exhibition-item {
        padding: 1.7rem 0 2.5rem 0;
        margin-left: 1.4rem;
        margin-right: 1.4rem;
    }

    .exhibition-item:last-of-type {
        padding-bottom: 0;
    }
}