footer, .footer-results {

    padding-top: $pad;
    // height: calc(100vh - 2 * #{$pad});
    height: 100vh; /* This is for browsers that don't support custom properties */
    height: calc(var(--vh, 1vh) * 100);
    
    @media #{$media-mobile-H} {
        max-height: 100vh; /* This is for browsers that don't support custom properties */
        max-height: calc(var(--vh, 1vh) * 100);
        min-height: unset;
    }

    display: flex;
    flex-direction: column;

    background-color: black;
    color: white;
    border-color: white;

    .homepage-menu {

        // @media #{$media-mobile} {
        //     height: 151.66px; //safari
        // }

        #homepage-logo {

            @media #{$media-tablet} {
                border-width: 2px 0 0 0;
                border-style: solid;
                border-color: white;
                padding-top: 14px;
            }
    
            img {
                filter: invert(100%);
            }
        }

        .homepage-selectors {
            margin-bottom: 0;
    
            @media #{$media-tablet} {
                margin-bottom: 13px;
            }
    
            li {
                border-width: $border-3 0 $border-3 0;
    
                .border-right {
                    border-width: 0 $border-3 0 0;
                }
    
                @media #{$media-tablet} {
                    border-width: 2px 0 2px 0;
    
                    .border-right {
                        border-width: 0 2px 0 0;
                    }
                }
            }
        }
    }
  
    nav.menu {
        border-width: 0 0 $border-3 0;
        margin-top: calc(#{$border-3} * 2);

        @media #{$media-tablet} {
            border-width: 0 0 2px 0;
        }

        @media #{$media-mobile} {
            margin-top: 0;
            // height: 41.5px; //safari 
        }

        ul{
            li.normal-item{
                a:hover, a:active, a:focus {
                    -webkit-text-stroke-color: white; 
                }

                @media #{$media-mobile} {
                    display: none;
                }
            }
    
            li.normal-item a,
            li.burger-item a,
            li.search-item a{
                -webkit-text-stroke-color: white; 
            }
        
            li.search-item {
                div.hover {
                    -webkit-text-stroke-color: black; 
                }
                img{
                    filter: none;
                }
            }
            li.active{
                a {
                    -webkit-text-stroke-color: white;
                }
            }

            li.burger-item{
                display: none;

                @media #{$media-mobile} {
                    display: block;
                }

                /* Styling the menu button */
                .burger-btn {
                    position: relative;
                    display: inline-block;
                    top: 5px;
                    margin-right: 8px;
                    transition: all 0.5s ease-out;

                    /* Styling the hamburger lines */
                    .btn-line {
                        width: 28px;
                        height: 2px;
                        margin: 0 0 5px 0;
                        background: white;
                        transition: transform 0.5s ease-out;
                    }
                }
            }
            
            li {
                @media #{$media-mobile} {
                    font-size: 22px;
                    letter-spacing: -0.02em;
                }

                a{
                    @media #{$media-mobile} {
                        height: 28px;
                        margin-top: -2.5px;
                        display: block;
                    }
                }
            }
        }
    }


    #social-menu {
        flex-grow: 1;

        align-self: end;
        padding-bottom: $pad;

        display: flex;
        flex-direction: row;
        align-content: end;
        width: 100%;

        background-color: black;

        @media #{$media-mobile} {
            display: grid;
            grid-template-rows: repeat(3, minmax(0, 1fr));
            padding-bottom: 5px;
            align-items: end;

            max-height: 40rem;

        }

        .menu-item {
            display: grid;
            grid-template-rows: auto 1fr;
            height: fit-content;
            align-self: end;
            // gap: 1em;

            min-width: fit-content;
            width: 25%;
            padding-right: 2em;

            h2{
                font-family: $abc;
                font-size: $size-30;
                line-height: 1.23em; // 37/30
                margin: 0;
                padding: 0;

                @media #{$media-mobile} {
                    font-family: $numberplate;
                    font-size: 22px;
                    line-height: 23.8px;
                    letter-spacing: -0.02em;
                }
            }

            ul{
                margin: 0;
                padding: 0;

                height: fit-content;

                // @media #{$media-mobile} {
                //     flex-direction: column;
                // }

                li{
                    margin: 0;
                    padding: 0;
                    font-family: $abc;
                    font-size: $size-30;
                    line-height: 1.23em; // 37/30

                    height: fit-content;

                    @media #{$media-mobile} {
                        font-family: $numberplate;
                        font-size: 22px;
                        line-height: 23.8px;
                        letter-spacing: -0.02em;
                    }
                }
            
            }            
        }

        .menu-item:first-of-type{
            @media #{$media-mobile} {
                flex-grow: 1;
            }
        }

        .legal{
            flex-grow: 1;
            align-self: end;

            display: flex;
            justify-content: end;

            @media #{$media-mobile} {
                flex-direction: column;
                flex-grow: 0;
            }
            
            li{
                margin-left: 2em;
                font-size: $size-20f;
                opacity: 0.5;

                @media #{$media-mobile} {
                    margin-left: 0;
                    font-size: 20px;
                    line-height: 26px;
                    letter-spacing: -0.04em;
                }
            }
        }

        a:hover, a:active, a:focus {
            color: transparent;
            -webkit-text-stroke-width: 0.04em; 
            -webkit-text-stroke-color: white; 
        }
    }
}

/* Adding transform to the X */
.main-menu.open .burger-btn {
    top: 4px;

    /* Styling the three lines
    to make it an X */

    .btn-line:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px);
    }
    .btn-line:nth-child(2) {
        opacity: 0;
    }
    .btn-line:nth-child(3) {
        transform: rotate(-45deg) translate(5px, -5px);
    }
}