.page-search{

    position: fixed;
    z-index: 12;
    display: none;
    overflow: auto;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    // transition: opacity 0.5s ease;
    // opacity: 0;

    .container{

        z-index: 13;
        position: relative;

        padding: 0 $pad;
        margin-top: calc(2.8rem + #{$header});

        min-height: calc(100vh - 2.8rem - #{$header});
        min-height: calc(var(--vh, 1vh) * 100 - 2.8rem - #{$header});

        display: flex;
        flex-direction: column;

        .close{

            display: block;
            padding: $pad-half 0;

            border-width: $border-3 0 $border-3 0;
            border-style: solid;       
            font-size: $size-40;

            @media #{$media-mobile} {
                font-size: 22px;
                height: 48px;
                border-width: 2px 0 2px 0;

            }

            a{

                display: grid;
                margin: 0 auto;
                width: fit-content;
                align-items: center;
                grid-gap: 0 11px;
                grid-template-columns: auto auto;

                transition: opacity 0.5s ease;

                .text {
                    display: inline-block;
                    text-align: center;
                    margin-bottom: 0;
                    text-transform: uppercase;
                    font-family: $numberplate;
                    letter-spacing: -0.02em;
                    line-height: 1;
                }

                .animated-cross-search{
                    display: inline-block;

                    @media #{$media-mobile} {
                        top: 0.46em;
                    }
                }

            }
        }

        form{

            flex-grow: 1;
            width: 100%;
            position: relative;

            input[type="text"] {
                text-transform: uppercase;
                font-family: $numberplate;
                font-size: $size-65-38;
                text-align: center;

                width: 100%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .tags{

            width: 100%;
            padding: $pad 0;

            @media #{$media-mobile} {
                margin-top: 2.8rem;
            }

            .text{
                margin: 0 auto 0.8rem auto;
                text-align: center;
            }

            ul{
                width: 65%;
                margin: 0 auto;
                text-align: center;

                @media #{$media-mobile} {
                    width: 100%;
                }

                li{
                    display: inline-block;
                    margin-right: 0.25em;
                    margin-bottom: 8px;
                    min-width: 7em;
                    text-align: center;

                    @media #{$media-mobile} {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .footer-results{
        display: none;
        opacity: 0;
        transition: opacity 0.5s ease;
    }
}

.science-universe .page-search {

    background-color: rgba(0, 0, 0, 1);

    .container, .container > * {
        background-color: rgba(0, 0, 0, 1);
    }

    .container form input[type="text"]::placeholder {
        color: white;
        opacity: 1;
    }

}

.art-universe .page-search, 
.home-universe .page-search {

    #search-science-logos {
        display: none;
    }

    background-color: rgba(255, 255, 255, 1);

    .container, .container > * {
        background-color: rgba(255, 255, 255, 1);
    }

    .container form input[type="text"]::placeholder {
        color: black;
        opacity: 1;
    }

}

.home-universe .page-search {

    #search-art-logos {
        display: none;
    }
    
    background-color: rgba(255, 255, 255, 0.9);

    @media #{$media-mobile} {
        background-color: rgba(255, 255, 255, 1);
    }

    .container {

        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);

        padding-top: calc(3.1rem + #{$header-homepage});
        margin: 0;

        // border-width: $border-5 0 $border-5 0;
        // border-style: solid;       

        // font-size: 3.5rem;
        // height: 1.85em;

        .close{
            @media #{$media-mobile} {
                height: 57px;
                display: grid;
            }
        }
    }

    .footer-results nav.menu{
        display: none;
    }
}


#search-art-logos, #search-science-logos{

    position: fixed;
    top: 0;
    left: 0;
    height: $header;
    width: 100%;
    padding: $pad;

    opacity: 0.1;
    cursor: pointer;

    @media #{$media-mobile} {
        opacity: 0;
    }

    display: grid;
    gap: 2vw;
    grid-template-columns: auto 1fr auto ;

    .main, .secondary{
        height: inherit;

        img{
            display: block;
            height: inherit;
        }
    }
    .button-side{
        height: $header;
    }
}

#search-art-logos{
    border-color: black;
    color: black;
}
#search-science-logos{
    border-color: white;
    color: white;
    
    .button-side{
        padding: 0.1em; /***correct***/
    }

}

.home-universe #search-art-logos,
.home-universe #search-science-logos{
    .main, .secondary,.button-side{
        opacity: 0;
    }
}

.home-universe #search-art-logos{
    height: 100vh;
}