//****************************************************************
// home hero covers

.template-home-common {

    padding-top: 0;
    padding-bottom: 2.5em;

    section {
        z-index: 2;
    }
    
    .hero-cover {

        // position: absolute;
        // z-index: -2;

        top: 0;
        left: 0;
        width: 100%;
        height: 80vh;
        // height: calc(var(--vh, 1vh) * 80);

        // make sure there's enough space for title in mobile
        padding-top: 38rem;
        min-height: fit-content;
    
        padding: $pad;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        // opacity: 0.4;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .year{
            font-family: $numberplate;
            font-size: $size-fixed-20;
            margin-bottom: 1rem;
        }
        .title{
            font-family: $abc;
            font-size: $size-45-55;
            margin-bottom: 1rem;
            letter-spacing: -0.04;
        }
    }

    .post-hero-cover {
        // margin-top: 80vh;
        // margin-top: calc(var(--vh, 1vh) * 80);
        // padding-top: 2*$pad;

        @media #{$media-mobile} {
            padding-top: 2*$pad;
        }
    }




    //****************************************************************
    // header inside home

    .section-header{

        padding-top: $pad;
        
        .logos{

            height: 4rem;
            gap: 1rem;

        }

        .side {
            align-self: center;

            .button-side{
                padding: 0.2em 0.4em;
                font-size: clamp(21px, 2.5vw, 33px);
                width: 100%;

                align-self: center;
        
                height: unset;
                padding: 0.0em 1em;
        
                writing-mode: inherit;
                text-orientation: inherit;
            }
        }
        
        nav.menu {
            padding: $pad-smaller 0;
        }
    }



    //****************************************************************
    // titles home

    .section-title{
        border-width: 0 0 $border-3 0;
        border-style: solid;
        margin-bottom: $pad-small;

        @media #{$media-tablet} {
            border-width: 0 0 2px 0;
        }

        display: flex;
        justify-content: space-between;

        h1 {
            line-height: 1;        
            padding: $pad-smaller 0;
        }

        a{
            display: block;
            align-self: center;

            .button{
                border-width: $border-3;

                @media #{$media-tablet} {
                    border-width: 2px;
                }

                @media #{$media-mobile} {
                    line-height: 32px;
                    height: 32px;
                    padding: 0 0.6em;
                }
            }
        }
    }

    //****************************************************************
    // about 

    .about-section {

        display: none;

        @media #{$media-mobile} {
            display: block;
        }

        margin-top: 7rem;

        &__text {
            font-size: $size-40;
            line-height: 1.175;
    
            p {
                margin: 1em 0;
            }
        }
    
        &__btn {
            margin: 4.5rem;
            display: block;
            text-align: center;
        }
    }
}

//     ///****************************************************************
//     // overlay of project

// 
//     a:hover .overlay {
//         opacity: 1;
//     }

//     .overlay {
//         opacity: 0;
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%,-50%);
//         z-index: 1;
//     }

//     .science-universe .overlay{
//         background-color: black;
//     }

//     .art-universe .overlay {
//         background-color: white;
//     }

//     .overlay-parent{
//         position: relative;
//         height: 100%;
//     }
