.template-works{
    
    padding-top: 0;

    .category-selector, .category-selector-inactive {

        padding: 2rem 0;

        .active.button:hover, 
        .active.button:active, 
        .active.button:focus {
            background-color: black;
            color: white;
            border-color: white;
        }
    }

    .NFT-grid{

        margin-top: -2rem;
        padding-bottom: 2rem;

        .horizontal-item {
    
            padding: 2rem 0;

            .image{
                height: unset;
                min-height: unset;

                @media #{$media-mobile} {
                    min-height: unset;
                }
            }
    
            .text {

                h3{
                    margin: 0.8em 0;
                }
    
                .abstract{

                    font-size: $size-20f;

                    @media #{$media-mobile} {
                        line-height: 23.09px;
                        letter-spacing: 0;
                    }

                    p, p:first-of-type {
                        margin-top: 0.5em;
                    }
                }
    
                .buttons{
                    margin-top: 0.8em;
                }
            }
        }
    
        .grid-item:last-of-type {
            border-style: none;
        }
    }

    @media #{$media-mobile} {

        .default-grid{
            margin-bottom: 0;
        }

        .default-grid:last-of-type{
            margin-bottom: 4rem;
        }
    }
}

// display selector + display adjustments

main.template-works.main-new.xtra-padding {
    padding: 16.3rem 0 3rem 0;

    .display-button {
        margin-bottom: calc(4rem - 50px);
        margin-right: 1.4rem;
        margin-left: auto;
        display: block;

        @media #{$media-mobile} {
            display: none;
        }

    }


    .collapse {
        display: none;
    }

    .horizontal-item {
        padding-bottom: 1.7rem;
        grid-template-columns: 24vw 1fr;

        .text {
            .year {
                margin-bottom: 0;
            }

            h2 {
                margin-bottom: 0.3em;
            }
        }

        .image {
            min-height: unset;
            height: auto;

            img {
                object-fit: contain;
                width: 100%;
            }
        }
    }

    .horizontal-item:first-of-type {
        padding-top: 0;
    }

    // .horizontal-item:hover .button, 
    // .horizontal-item:active .button, 
    // .horizontal-item:focus  .button {
    //     transition: background-color 0.5s ease, 
    //         color 0.5s ease,
    //         border-color 0.5s ease;
    //     background-color: black;
    //     color: white;
    //     border-color: black;
    // }
}