.template-home-art {

    background-color: white;

    .hero-cover {

        color: white;

        .button:hover, 
        .button:focus, 
        .button:active {
            background-color: white;
            color: black;
            border-color: white;
        }
    }

    .section-header .side .button-side {
        padding: 0 0.5em;
    }

    .section-exhibition{

        @media #{$media-mobile} {
            margin-top: 8rem;
        }

        .exhibitions-grid{
            grid-template-columns: repeat(3, minmax(0, 1fr));
            margin-bottom: 5em;
    
            @media #{$media-mobile} {
                display: block;
                margin-bottom: 0;
            }
    
            .grid-item{
    
                .year{
                    margin: 0.6em 0;
    
                    @media #{$media-mobile} {
                        margin: 0.85em 0 0.2em 0;
                        font-size: 16px;
                    }
    
                }
                h2{
                    text-transform: uppercase;
                    font-family: $numberplate;
                    font-size: $size-40-h2;
                }
    
                @media #{$media-mobile} {
                    margin-bottom: 36px;

                    img{
                        height: 417px;
                        object-fit: cover;
                    }
                }

            }
        }
    }

    .works-grid{

        .grid-item{

            h2{
                font-size: $size-70-h1;
                margin-top: -0.16em;
                margin-bottom: 1.5em;
            }
        }
    }

}