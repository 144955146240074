// homepage logo and section selectors

#homepage-logo {

    display: block;

    img {
        width: 100%; /* original image 1620 × 225 */
        height: auto;
    }
}

.homepage-selectors {

    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    font-size: $size-70-h1;
    letter-spacing: -0.02em;

    height: $homepage-selectors-height;
    margin: $pad 0;

    cursor: pointer;

    li{
        display: inline-block;
        text-align: center;
        height: inherit;

        border-width: $border-5 0 $border-5 0;
        border-style: solid;

        @media #{$media-desktop} {
            border-width: 4px 0 4px 0;
        }

        @media #{$media-tablet} {
            border-width: 3px 0 3px 0;
        }

        @media #{$media-mobile} {
            border-width: 2px 0 2px 0;
            height: 71px;
        }

        h1{
            font-size: $size-70-h1;
            letter-spacing: -0.02em; 
            
            @media #{$media-mobile} {
                font-size: 30px;
            }
        }

        a{
            position: relative;
            display: block;
            top: 50%;
            transform: translateY(-50%);

            .border-right {
                border-width: 0 $border-5 0 0;
                border-style: solid;

                @media #{$media-desktop} {
                    border-width: 0 4px 0 0;
                }
        
                @media #{$media-tablet} {
                    border-width: 0 3px 0 0;
                }
        
                @media #{$media-mobile} {
                    border-width: 0 2px 0 0;
                }
            }

            .menu-item {

                width: 100%; 
                height: 75%;

                h1 {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                }
            }
        }
    }

    /* mobile animation */

    li.active{
        overflow: hidden;
        .menu-item{
            animation-name: alive;
            animation-duration: 0.8s;
            animation-delay: 0;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-timing-function: linear;
    
            border: 0
        }
    } 

    @keyframes alive {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(1.2);
        }
    }
}


.homepage-selectors:hover li a, 
.homepage-selectors:active li a, 
.homepage-selectors:focus li a,
.homepage-selectors.active li a,
.template-homepage.active .homepage-selectors li a{

    .border-right{
        border-width: 0!important;
    }

    h1{
        opacity: 0.4;
    }
} 

.homepage-selectors {

    li:hover, li:active, li:focus {
        background-color: white;
        padding: 0;
        border-color: white;
        a .menu-item h1 {
            opacity: 1;
        }      
    }
}

/* SELECTORS TEXT COLOR */

// not in homepage
.homepage-selectors li { 
    color: white;
}

// in homepage
.template-homepage .homepage-selectors li { 
    color: black;
}

// in homepage (active)
.template-homepage.active .homepage-selectors li { 
    color: white;
}

// hover
.homepage-selectors li:hover,
.homepage-selectors li:focus,
.homepage-selectors li:active,
.template-homepage.active .homepage-selectors li:hover,
.template-homepage.active .homepage-selectors li:focus,
.template-homepage.active .homepage-selectors li:active{ 
    color: black;
}