#iframe-popup{
    position: fixed;
    z-index: 20;

    top: 5%;
    bottom: 5%;
    left: 20%;
    right: 20%;

    @media #{$media-mobile} {
        top: 6rem;
        left: 0;
        right: 0;
        bottom: 6rem;
    }


    display: none;

    .close{
        cursor: pointer;
        position: absolute;
        right: 1.25em; 
        top: 1.2em;

        h5 {
            display: inline-block;
        }
        
        img {
            display: inline-block;
            position: relative;
            top: -0.35em;
            right: -0.2em;
            width: 15px;
        }
    }

    iframe {
        display: none;
        height: 100%;
        width: 100%;

        html {
            overflow: none;
        }
    }

}

// section particularities
.art-universe #iframe-popup{
    background-color: white;
}

.science-universe #iframe-popup {
    background-color: black;
}

#iframe-popup.image {
    top: 5%;
    bottom: 5%;
    left: 20%;
    right: 20%;

    @media #{$media-mobile} {
        top: 6rem;
        left: 0;
        right: 0;
        bottom: 6rem;
    }

}

#iframe-popup.book {
    top: 5%;
    bottom: 5%;
    left: 25%;
    right: 25%;

    @media #{$media-tablet} {
        top: 5%;
        bottom: 5%;
        left: 15%;
        right: 15%;
    }

    @media #{$media-mobile} {
        top: 5rem;
        left: 0;
        right: 0;
        bottom: 5rem;
    }
}

.popup-trig{
    cursor: pointer;
}

// .popup-container-effect {
//     filter: blur(60px) grayscale(80%);
//     -webkit-filter: blur(60px) grayscale(80%);
//     transition: filter 1s ease;
//     pointer-events: none;
//     cursor: default;
// }

#iframe-bg{
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 19;
    display: none;
}