// Basics
@import "fonts";
@import "variables";
@import "base";

// Partials
@import "partials/grids";
@import "partials/animated-cross";
@import "partials/button-normal";
@import "partials/button-full";
@import "partials/button-side";
@import "partials/menu";
@import "partials/menu-homepage";
@import "partials/header";
@import "partials/footer";
@import "partials/iframe-popup";
@import "partials/search";
@import "partials/search-results";
@import "partials/fancybox";


// common templates
@import "templates/homepage";
@import "templates/home-common"; 
@import "templates/detail-common"; 
@import "templates/about-common"; 
@import "templates/book-common"; 
@import "templates/legal";

// science templates
@import "templates/science/science-home";
@import "templates/science/science-detail"; 
@import "templates/science/publications"; 
@import "templates/science/projects";

// art templates
@import "templates/art/art-home";
@import "templates/art/art-detail"; 
@import "templates/art/art-image"; 
@import "templates/art/works"; 
@import "templates/art/exhibitions"; 
@import "templates/art/exhibition"; 



