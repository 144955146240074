.template-science-detail {

    background-color: black;
    
    .detail-header {
        .right-side {
            border-color: white;
        }
    } 

    #related-section,
    #related-section .default-grid {
        opacity: 1;
    }

}