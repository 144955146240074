.template-detail-exhibition{

    .detail-header{
        grid-template-columns: 62% 1fr;
        gap: 0;

        .left-side{
            .main-text{
                border-style: solid;
                border-width: 0 3px 0 0;
                padding-right: 3em;

                @media #{$media-mobile} {
                    border-width: 0;
                    padding-right: 0;
                }
            }
            .secondary-text{
                margin-right: 0;
                margin-left: 0;
                padding-right: 6em;

                @media #{$media-mobile} {
                    padding-right: 0;
                }
            }
        }

        .right-side{
            border-width: 0;

            @media #{$media-mobile} {
                border-width: 2px 0 0 0;
                border-color: black;
                padding-top: 3rem;
                margin-bottom: 9rem;
            }

            .links{

                margin-bottom: 4rem;

                .buttons{
                    .button{
                        margin-right: 0.25em;
                        margin-bottom: 0.5em;
                        min-width: 7em;
                        text-align: center;
                    }
                }
            }


            .works{
                text-align: left;
    
                font-size: $size-35-27;
                line-height: 1.3;
    
                a {
                    text-decoration: underline;
                }

                a:hover{
                    text-decoration: none;
                }
    
                .work{
                    display: inline-block;
                    min-width: unset;
                }
                .work::after{
                    content:",";
                }
                .work:last-of-type:after{
                    content:"";
                }
            }
        }
    }

    .related{

        .horizontal-wrapper{
            margin-top: -1.7rem;
            margin-bottom: 1.7rem;

            .horizontal-item{

                border-width: 0;
                @media #{$media-mobile} {
                    border-width: 0 0 2px 0;
                }

                .abstract{
                    @media #{$media-mobile} {
                        display: none;
                    }
                }
            }
        }

    }
}