.template-legal {
}

.header-legal {
    filter: invert(100%);
    margin-bottom: 4rem;
}

.content-legal{
    &__title {
        margin-bottom: 0.4em;
    }
}