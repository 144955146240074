.template-art-detail {

    // background-color: black;
    
    .detail-header {
        .team {
            border-color: black;
        }
    } 

    .first-images{
        border-color: black;
    }

    .swiper {
        h3{
            border-width: 0 0 $border-3 0;
            border-color: black;
            border-style: solid;

            padding-bottom: 0.3em;
            margin-bottom: 0.7em;
        }

        .swiper-wrapper .swiper-slide {

            display: grid;
            grid-template-rows: 1fr min-content;

            .image {
                display: block;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }

            .video {
                position: relative;

                video {
                    display: block;
                    width: 100%;
                    max-height: calc(80vh - 3em);
                    object-fit: contain;
                }
                .js-video-player-play {
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    cursor: pointer;

                    &.playing {
                        display: none;
                    }
                }
            }


            .image-description{
                margin: 0 auto;
                padding: 1rem;
                text-align: center;
            }
        }
    }
}
