/******************************************************************/
/*** The new CSS Reset - version 1.2.0 (last updated 23.7.2021) ***/

/* Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property */
*:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
    all: unset;
    display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/*
Remove list styles (bullets/numbers)
in case you use it with normalize.css
*/
ol, ul {
    list-style: none;
}

/* For images to not be able to exceed their container */
img {
    max-width: 100%;
}

/* Removes spacing between cells in tables */
table {
    border-collapse: collapse;
}

/* Revert the 'white-space' property for textarea elements on Safari */
textarea {
    white-space: revert;
}


/****************************************************************
/ https://css-tricks.com/css-fix-for-100vh-in-mobile-webkit/
/****************************************************************/

body {
    min-height: 100%;
    min-height: -webkit-fill-available;
}

html {
    height: -webkit-fill-available;
}



/****************************************************************
/ custom scrollbar
/****************************************************************/

body {
    overflow: auto;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
    height: 10px;
}
  
/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}
  
/* Handle */
::-webkit-scrollbar-thumb{
    background: rgb(160, 160, 160);
}

/* Handle on hover */
.science-universe::-webkit-scrollbar-thumb:hover{
    background: white
}

.art-universe::-webkit-scrollbar-thumb:hover, 
.home-universe::-webkit-scrollbar-thumb:hover {
    background: #232323;
}

/* remove scrollbar */
body.overflow-hidden{
    overflow: hidden;
}


/****************************************************************
/ GENERAL STYLING
/****************************************************************/

html {
    font-size: $size-root;
}

body {
    margin: 0;

    font-family: $abc;
    font-size: $size-20f;
    line-height: 1.25;
    
    // font rendering and aliasing
    text-rendering: geometricPrecision;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}  

header {
    padding: $pad $pad 0 $pad;
}

footer, .footer-results {
    padding: 0 $pad $pad $pad;
}

// main {
//     padding: 7rem $pad;
// }

section {
    padding-left: $pad;
    padding-right: $pad;
}

section.no-horizontal-padding {
    padding-left: 0;
    padding-right: 0;
}


ul, ol {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

em, i {
    font-style: italic;
}

h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    font-family: $numberplate;
    font-weight: normal;
}

h1 {
    display: block;
    margin: 0;
    padding: 0;
    font-size: $size-70-h1;
}

h2 {
    font-size: $size-40-h2;
    line-height: 1em;
    letter-spacing: -0.04;
}

h3, h4, h5 {
    font-size: $size-fixed-20;
    line-height: 1em;
}

p a{
    text-decoration: underline;
}

p a:hover, p a:focus, p a:active{
    text-decoration: none;
    color: transparent;
    -webkit-text-stroke-width: 0.04em;
}





.science-universe {
    background-color: black;
    color: white;
    border-color: white;

    // .logos .main, .logo{
    //     filter: invert(100%);
    // }

    p a:hover, p a:focus, p a:active{
        -webkit-text-stroke-color: white;
    }
}

.art-universe, .home-universe {
    background-color: white;
    color: black;
    border-color: black;

    p a:hover, p a:focus, p a:active{
        -webkit-text-stroke-color: black;
    }
}

/****************************************************************
/ UTILITIES
/****************************************************************/

.bg-cover{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-contain{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.filter-grayscale{
    filter: grayscale(100%);
}

.richtext-content{
    p{
        padding-top: 0.7em;
    }

    p:first-of-type{
        padding-top: 0.5em;
    }
    h1,h2,h3,h4,h5,h6{
        font-family: $numberplate;
        font-size: $size-50;
        line-height: inherit;
        margin: 0;
        padding-top: 1.2em;
        border: 0;
    }
}

// empty images - remove space
img[src=""],
img[src="/media/"] {
    height:0!important;
}


// smart anchor (taking in account header height)
// ------------------------------------
div.anchor {
    display: block;
    position: relative;
    visibility: hidden;
    top: calc(1px - #{$header} - #{$pad});
    // @media #{$media-tablet} {
    //     top: calc(1px - #{$header-h-m} - #{$mobilebar-h-m});
    // }
}

/****************************************************************
/ PAGINATOR
/****************************************************************/

.pagination{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding: 0 $pad;
    
    .button{
        display: inline-block;

        border-radius: 2em;
        width: 75px;
        display: grid;
        height: 58px;
        border: 3px solid white;
        align-items: center;
        justify-items: center;
        justify-content: center;
        padding: 0;

        z-index: 10;
        background-color: black;

        @media #{$media-tablet} {
            border-width: 2px;
        }

        @media #{$media-mobile} {
            height: 34px;
            width: 48px;
            border-width: 2px;
        }

        .arrow{
            transition: filter 0.6s ease;

            @media #{$media-mobile} {
                width: 80%;
            }
        }
    }

    .button:hover, .button:active, .button:focus {
        .arrow{
            filter: invert(1);
        }
    }
}

.art-universe .pagination{
    .button{
        background-color: white;
        border-color: black;
        .arrow{
            filter: invert(1);
        }
    }

    .button:hover, .button:active, .button:focus {
        .arrow{
            filter: none;
        }
    }
}


/****************************************************************
/ animation
/****************************************************************/

// animate by mouse
.mouseanimate-div {
    opacity: 0;
    transition: opacity 0 ease; 
    // transition: opacity 1s;
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    pointer-events: none;

    border-width: $border-3;

    @media #{$media-tablet} {
        border-width: 2px;
    }
    @media #{$media-mobile} {
        opacity: 0;
        display: none;
    }
}

.science-universe .mouseanimate-div{
    background-color: black;
}

.art-universe .mouseanimate-div,
.home-universe .mouseanimate-div {
    background-color: white;
}

.search-page .mouseanimate-div {
    z-index: 1000;
}

.results-loader{
    cursor: pointer;
}

.animated-thumb-wrapper{
    img {
        position: absolute;
        opacity: 0;
        transition: top 0.3s ease, left 0.3s ease;
        z-index: 10;
        pointer-events: none;
        max-width: 300px;
    }
}

/****************************************************************
/ PAGES TYPES
/****************************************************************/

.main-list{
    margin-top: 0;
}