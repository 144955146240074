main.main-new.template-about-common{

    padding-top: 17.7rem;

    @media #{$media-tablet} {
        border-width: 0 0 2px 0;
    }
    
    h1, .subheading {
        border-width: 0 0 $border-3 0;
        border-style: solid;

        @media #{$media-tablet} {
            border-width: 0 0 2px 0;
        }
    }

    h1{
        font-size: $size-65;
        padding: 0.1em 0;
        margin: 0.8em 0 0.5em 0;
    }

    section:first-of-type h1 {
        margin-top: 0;
    }

    // compensation of header in anchors
    // section{
    //     padding-top: 7.2rem;
    //     margin-top: -7.2rem;


    //     @media #{$media-tablet} {
    //         padding-top: 8rem;
    //         margin-top: -8rem;
    //     }

    //     @media #{$media-mobile} {
    //         padding-top: 125px;
    //         margin-top: -125px;
    //     }

    // }
    // compensation of header in anchors (first section not needed)
    // section:first-of-type{
    //     padding-top: 0;
    //     margin-top: 0;
    // }

    h2{
        font-size: $size-40-h2-bis;
        padding: 0.3em 0;
    }

    .subheading {

        padding: 0.5em 0.3em;

        @media #{$media-mobile} {
            padding: 0.3em 0;
        }

        .title{
            display: grid;
            grid-template-columns: 1fr auto;
            //padding-right: 0.3em;
    
            .animated-cross {
                top: 0.6em;

                @media #{$media-mobile} {
                    top: 0.3em;
                }
            }
        }

        .animated-cross-display > div {
            margin-top: 1em;
        }
    }

    .subheading-L{
        margin-bottom: 5em;
    }

    .noline{
        border-width: 0;
    }

    #section-team{
        h1{
            margin-bottom: 0;
        }
    }

    .lab-text{
        font-size: $size-40;
        line-height: 1.175;

        p {
            margin: 1em 0;
        }
    }

    .director-grid{
        display: grid;
        grid-template-columns: 1fr 43%;
        grid-template-rows: auto 1fr;

        grid-template-areas:
        'name picture'
        'text picture';

        margin-top: 3em;
        gap: 0 2.4em;
        margin-bottom: 4em;

        @media #{$media-mobile} {

            grid-template-columns: 100%;
            grid-template-rows: auto auto auto;

            margin-top: 3rem;
            gap: 3rem 0;

            grid-template-areas:
            'name'
            'picture'
            'text';
        }

        .name{
            grid-area: name;

            font-size: $size-70-h2;
            letter-spacing: -0.04em;

            line-height: 1.14;
            font-family: $abc;
            margin-bottom: 0.5em;

            @media #{$media-mobile} {
                line-height: 1.22;
                margin-bottom: 0;
            }
        }

        .text{
            grid-area: text;

            p{ margin-bottom: 1em;}
        }

        .picture {
            grid-area: picture;
            display: block;
            // height: 100%;
            // width: auto;
            // max-height: 70vh;
            width: 100%;
        }
    }

    .grid-team{

        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap: 1em;
        display: grid;
        margin: $pad 0;

        @media #{$media-mobile} {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            margin: $pad 0 3*$pad 0;
        }

        .grid-item{

            margin-bottom: 2.5em;
            @media #{$media-mobile} {
                margin-bottom: $pad;
            }

            // make div square
            div{
                position: relative;
            }
            div:before{
                content: "";
                display: block;
                padding-top: 100%;
            }
            
            h2{
                font-size: $size-35-22;
                margin-top: 0.6em;
                padding: 0;

                @media #{$media-mobile} {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    margin-top: 0.4em;
                }
            }

            h3{
                margin-top: 0.8em;
                font-size: $size-20f;
                line-height: 1.25;

                @media #{$media-mobile} {
                    margin-top: 0.4em;
                }
            }
        }
    }

    .grid-books{
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: 0.45em;

        @media #{$media-mobile} {
            margin-bottom: 5rem;
        }

        .bg {
            height: 28vw;
        }

        .text{
            h2{
                font-size: $size-35-27;
                padding: 0;
            }

            h3{
                font-size: $size-20f;
            }
        }

    }

    // masonry grid

    .masonry-grid{

        .masonry-item{
            width: calc(50% - 7.5px);
            margin-bottom: 3em;

            @media #{$media-mobile} {
                width: 100%;
            }
        }
    }

    .grid-videos, .grid-press {

        /////
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 0.85em;
        /////

        @media #{$media-mobile} {
            display: block;
        }

        img{
            width: 100%;
            height: auto;
        }

        a{
            display: block;
            //width: 40%;
        }

        h2 {
            font-family: $numberplate;
            font-size: $size-65;
            letter-spacing: -0.04em;

            line-height: 1;
            margin-bottom: 0;
            padding: 1.8rem 0 0.5rem 0;

            @media #{$media-mobile} {
                font-size: 27px;
                letter-spacing: -0.02em;
                padding: 1.2rem 0 0.1rem 0;
            }
        }

        .description {
            font-size: $size-25f;
            line-height: 1.36;
        }

    }

    .grid-press {

        @media #{$media-mobile} {
            margin-bottom: 3rem;
        }

        .masonry-item{
            @media #{$media-mobile} {
                margin-bottom: 2.3rem;
            }
        }
    }


    .grid-videos{

        // @media #{$media-mobile} {
        //     margin-bottom: -3em;
        // }

        .images{

            position: relative;

            .preview {
                width: 100%;
                height: auto;
            }

            .play {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 90px;
                transition: filter 0.5s ease;

                @media #{$media-mobile} {
                    width: 73.26px;
                }
            }
        }

        a:hover, a:focus, a:active {
            .play {
                filter: invert(100%);
            }
        }
    }

    .qa{
        .qa-grid{
            display: grid;
            grid-template-columns: 1fr 43%;
            grid-template-rows: auto auto auto;
    
            grid-template-areas:
            'title picture'
            'subtitle picture'
            'text picture';
            
            gap: 0 4em;

            @media #{$media-mobile} {
                display: block;
            }

            .title{
                grid-area: title;

                font-size: $size-70;
                line-height: 1.143;
                font-family: $abc;
                text-transform: none;
                margin-bottom: 0.5em;

                @media #{$media-mobile} {
                    line-height: 1.13;
                    font-size: 31px;
                }
            }

            .subtitle{
                grid-area: subtitle;
                margin-bottom: 1.8rem;
            }

            .text-first{
                grid-area: text;
            }

            .picture{
                grid-area: picture;
                margin-right: 1em;
            }
        }

        .question, .answer{
            line-height: 1.2;
            p{ 
                margin-bottom: 0.8em;
            }
        }

        .question{
            text-decoration: underline;
            margin: 2.2em 0;
        }

        .middle-images{

            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
    
            margin-top: 0.8em;
            margin-bottom: 1.5em;
            gap: 1.6em;
    
            div {
                height: 30vw;
            }

            @media #{$media-mobile} {
                display: none;
            }
        }

        .middle-images-mobile{
            display: none;

            margin: 2*$pad 0;

            .top-image{
                margin: $pad 0 2*$pad 0;
            }

            @media #{$media-mobile} {
                display: block;
            }
        }
    }

    .grid-logos{
        .grid-item{
            div{
                margin: 3em;
            }
        }
    }

    .alumni-list{
        margin: 2em 0.3em;
        column-count: 4;

        font-size: $size-35-22;
        line-height: 1.43;
        font-family: $numberplate;

        @media #{$media-mobile} {
            column-count: 2;
            font-size: 16px;
            margin-bottom: 6rem;
        }
    }

    .job-list{

        .job-item{

            border-width: 0 0 1px 0;
            border-style: solid;
            margin-bottom: 1.2em;

            h4{
                margin-bottom: 0.8em;
                margin-top: 2.3rem;

                @media #{$media-mobile} {
                    margin-bottom: 0.5rem;
                    margin-top: 3rem;
                }
            }

            .job-heading{

                display: grid;
                grid-template-columns: 1fr auto;
                gap: 1em;

                margin-bottom: 0.7em;

                @media #{$media-mobile} {
                    display: block;
                    margin-bottom: $pad;
                }

                h2{
                    font-size: $size-65;
                    line-height: 1;
                    padding: 0;

                    @media #{$media-mobile} {
                        margin-bottom: 1.1rem;
                    }
                }

                .button{
                    // height: fit-content;
                    margin-top: 0.25em;

                    @media #{$media-tablet} {
                        border-width: 2px;
                        padding: 0 1.5em 2.4em 1.5em;
                        margin-bottom: 0.5rem;
                    }
                }
            }

            .job-text{
                display: none;
                font-size: $size-40;
                line-height: 1.175;
                margin-bottom: 2.2rem;
                overflow: hidden;

                @media #{$media-mobile} {
                    margin-bottom: 3rem;
                }

                p{
                    padding-bottom: 0.7em;
                    
                }

                h2{
                    padding: 1.4em 0 0.8em 0;
                }

                ul { 
                    list-style: disc; 
                    padding-left: 2*$pad;

                }
            }

            .job-text > *:first-child{
                padding-top: 2*$pad;
                
            }


        }

        .job-item:last-of-type{
            border-width: 0;
        }

        .grid-press {
            margin-bottom: 5em;
        }
    }
}