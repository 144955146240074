.template-art-image{

    padding: 2em;

    height: 100%;
    width: 100%;
    background-color: white;
    color: black;

    display: grid;
    // grid-template-columns: 60% 40%;

    .image{
        background-position: left top;
    }

    .secondary-text {
        padding-right: 1em;
        margin: 0 2em;
        font-size: $size-20f;

        .tags{
            margin-top: 2em;
        }

        div:first-of-type {
            margin-top: 0;
        }
    }
}