nav.menu {

    border-width: $border-3 0 $border-3 0;
    border-style: solid;


    @media #{$media-tablet} {
        border-width: 2px 0 2px 0;
    }

    padding: $pad-half 0;

    transition: padding-top $ani ease, padding-bottom $ani ease;
    transition: padding-top $ani ease, padding-bottom $ani ease, font-size $ani ease;

    ul {

        display: flex;
        justify-content:  space-between;

        li {
            display: inline-block;

            text-transform: uppercase;
            font-family: $numberplate;
            font-size: $size-40;
            letter-spacing: -0.02em;

            // margin-top: -0.14em;
            margin-bottom: 0;
        }

        li.normal-item, li.burger-item{
            a:hover, a:active, a:focus {
                color: rgba(0,0,0,0);
                -webkit-text-stroke-width: 1px; /* 1px Stroke */
                // -webkit-text-stroke-color: #ffffff; /* White Color */
                transition: -webkit-text-stroke-width 0.1s ease-in-out; /* Displays in 1 Second */
                transition: color 0.1s ease-in-out; /* Displays in 1 Second */
            }
        }

        li.search-item{
            padding-right: 0.96em;
            position: relative;
            width: 4em;

            a {
                position: absolute;
                top: 0;
                left: 0;

                img {
                    display: inline-block;
                    position: relative;
                    width: 0.96em;
                    top: 0.02em;
                    padding-left: 0.2em;
                }
            }

            div.normal {
                opacity: 1;
            }
            div.hover {
                opacity: 0;
                color: rgba(0,0,0,0);
                -webkit-text-stroke-width: 1px; /* 1px Stroke */
                // -webkit-text-stroke-color: #ffffff; /* White Color */

                img {
                    opacity: 0.85;
                }
            }
        }

        li.search-item:hover, li.search-item:active, li.search-item:focus {
            div.normal {
                opacity: 0;
                transition: opacity 0.1s ease-in-out;
            }
            div.hover {
                opacity: 1;
                transition: opacity 0.1s ease-in-out;
            }
        }

        li.active{
            a {
                color: rgba(0,0,0,0);
                -webkit-text-stroke-width: 1px; /* 1px Stroke */
                // -webkit-text-stroke-color: #ffffff; /* White Color */
            }
            div.normal {
                opacity: 0;
            }
            div.hover {
                opacity: 1;
            }
        }
    }
}


.header-new {

    .logos-inner{
        display:none;

        @media #{$media-mobile} {
            display:grid;
        }

        grid-template-columns: auto 1fr auto ;

        .main {
            img{
                display: block;
                filter: invert(1);
            }
        }
    }

    nav.menu {

        .social-menu{
            display: none;
        }

        @media #{$media-mobile} {

            position: fixed;
            top: -100%;
            left: 0;
            width: 100%;
            height: 100vh; /* This is for browsers that don't support custom properties */
            height: calc(var(--vh, 1vh) * 100);

            color: white;

            opacity: 0;
    
            z-index: 30;
            background-color: black;
    
            transition: all 0.5s ease-in-out;
    
            border-width: 0;
            padding: $pad;
            display: flex;
            flex-direction: column;

            .logos-wrapper{
                height: 20vh; /* This is for browsers that don't support custom properties */
                height: calc(var(--vh, 1vh) * 20);
            }

            .close-menu{
                display: flex;
                align-items: center;
                margin-left: 40px;
                gap: 0 5px;

                h4{
                    font-size:22px;
                    padding-top: 1px;
                }
            }


            .social-menu {

                background-color: black;

                // safari drama
                // flex-grow: 1;
                height: calc(80vh - 220px); /* This is for browsers that don't support custom properties */
                height: calc(var(--vh, 1vh) * 80 - 220px);

                align-self: end;
        
                align-content: end;
                width: 100%;
        
                display: grid;
                grid-template-rows: repeat(2, minmax(0, 1fr));
                padding-bottom: 5px;
                align-items: end;
        
                .menu-item {
                    display: grid;
                    grid-template-rows: auto 1fr;
                    height: fit-content;
                    align-self: end;
                    // gap: 1em;
        
                    min-width: fit-content;
                    width: 25%;
                    padding-right: 2em;
        
                    h2{
                        margin: 0;
                        padding: 0;

                        font-family: $numberplate;
                        font-size: 22px;
                        line-height: 23.8px;
                        letter-spacing: -0.02em;
                    }
        
                    ul{
                        margin: 0;
                        padding: 0;
                        flex-direction: column;

                        li{
                            margin: 0;
                            padding: 0;
                            display: block;

                            font-family: $numberplate;
                            font-size: 22px;
                            line-height: 23.8px;
                            letter-spacing: -0.02em;
                        }
                    
                    }            
                }
        
                .menu-item:first-of-type{
                    flex-grow: 1;
                }

                a:hover, a:active, a:focus {
                    color: transparent;
                    -webkit-text-stroke-width: 0.04em; 
                    -webkit-text-stroke-color: white; 
                }

                .legal a:hover, .legal a:active, .legal a:focus {
                    -webkit-text-stroke-width: 0.06em; 
                }
            }

            .main-menu{

                flex-direction: column;
                height: 220px; // safari

                .normal-item{
                    border-width: 2px 0 0 0;
                }
                .search-item{
                    border-width: 2px 0 2px 0;
                    .flex-container{
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                    }

                    .flex-container > *{
                        display: block;   
                    }
                }


                .normal-item, .search-item{
                    border-style: solid;
                    border-color: white;
                    width: 100%;
                    height: 5.5rem;
                    line-height:  5.5rem;
                    font-size: 3rem;
                    letter-spacing: -0.02em;

                }

                // colors hover
                li.normal-item{
                    a:hover, a:active, a:focus {
                        -webkit-text-stroke-color: white; 
                        color: transparent;
                    }
                }
        
                li.normal-item a,
                li.search-item a{
                    -webkit-text-stroke-color: white; 
                    color: white;
                }
            
                li.search-item {
                    div.hover {
                        -webkit-text-stroke-color: black; 
                        height: 5.5rem;
                        a span {
                            color: transparent;
                        }
                    }
                    img{
                        filter: none;
                    }
                }
                li.active{
                    a {
                        -webkit-text-stroke-color: white;
                        color: transparent;
                    }
                }
            }
        }
    }

    nav.menu.show{
        @media #{$media-mobile} {
    
            position: fixed;
            top: 0;
            opacity: 1;

            transition: all 0.5s ease-in-out;
        }
    }


    nav.menu-about{
        border-width: 0 0 $border-3 0;
        opacity: 1;
        
        .mobile {
            display: none;
        }

        @media #{$media-tablet} {
            border-width: 0 0 2px 0;
        }

        @media #{$media-mobile} {

            position: fixed;
            bottom: 0;
            top: unset;
            left: 0;
            right: 0;
            height: fit-content;
            opacity: 1;
            z-index: 29;

            border-width: 0;
            padding: 0;

            text-align: center;

            .border-wrapper{
                margin: 0 $pad;
                padding: $pad 0;
                border-width: 2px 0 0 0;
                border-style: solid;
                // color set in header.scss, according to header color
            }

            .ul{
                display: block;
                
                .normal-item{
                    font-size: 22px;
                    letter-spacing: -0.02em;
                    display: block;
                    margin-bottom: 1.4rem;
                }
            }


            .mobile{

                display: grid;
                grid-template-columns: auto auto;
                width: fit-content;
                margin: 0 auto;
                align-items: center;

                .section{
                    font-family: $numberplate;
                    font-size: 22px;
                    letter-spacing: -0.02em;
                    line-height: 1;

                    margin-right: $pad;
                }
                .arrow{
                    padding-bottom: 3px;
                }
            }

            .ul{
                display: none;
            }
        }
    }
}