// header side buttons

.button-side{

    cursor: pointer;

    width: 1.5em;
    min-width: 36px;

    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;

    border-width: $border-3;
    border-style: solid;
    border-radius: 2em;

    text-transform: uppercase;
    text-align: center;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    font-size: $size-button-side;
    font-family: $numberplate;

    transition: background-color 0.5s ease, 
        color 0.5s ease,
        border-color 0.5s ease;
}

.button-side:hover, 
.button-side:focus, 
.button-side:active {
    transition: background-color 0.5s ease, 
        color 0.5s ease,
        border-color 0.5s ease;

}


.art-universe {

    header .button-side, .page-search .button-side{

        font-size: calc(#{$size-button-side}*0.87);
        width: 1.8em;

        @media #{$media-desktop} {
            width: 1.6em;
            border-radius: 1.5rem;
            font-size: calc(#{$size-button-side}*0.76);
        }

        @media #{$media-tablet} {
            font-size: calc(#{$size-button-side}*0.7);
        }
    }
}
