.button {
    display: inline-block;
    cursor: pointer;
    width: fit-content;
    
    font-family: $numberplate;  
    font-size: $size-fixed-20;

    padding: 0 1em 2.4em 1em;
    height: 50px;
    line-height: calc(50px - 3px); // font not vertically centered

    border-width: $border-2;

    border-style: solid;
    border-radius: 2em;
    text-transform: uppercase;

    // transition: background-color 0.5s ease, 
    //     color 0.5s ease;
    transition: all 0.5s ease;
}

.science-universe {

    .button:hover, 
    .button:active, 
    .button:focus {
        background-color: transparent;
        color: white;
        border-color: white;
    }


    .button:hover, 
    .button:active, 
    .button:focus {
        // transition: background-color 0.5s ease, 
        //     color 0.5s ease,
        //     border-color 0.5s ease;
        background-color: white;
        color: black;
        border-color: white;
    }

    .active.button {
        background-color: white;
        color: black;
        border-color: white;
    }

    .active.button:hover, 
    .active.button:active, 
    .active.button:focus {
        background-color: black;
        color: white;
        border-color: white;
    }
}

.art-universe, .home-universe {
    .button:hover, 
    .button:active, 
    .button:focus {
        transition: background-color 0.5s ease, 
            color 0.5s ease,
            border-color 0.5s ease;
        background-color: black;
        color: white;
        border-color: black;
    }

    .active.button {
        background-color: black;
        color: white;
        border-color: black;
    }

    .active.button:hover, 
    .active.button:active, 
    .active.button:focus {
        background-color: white;
        color: black;
        border-color: black;
    }
}

