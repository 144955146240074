.template-detail-common {

    padding-top: 0;

    .hero-cover {

        .year{
            font-family: $numberplate;
            font-size: $size-fixed-20;
            margin-bottom: 0.4rem;
        }
        .title{
            font-family: $abc;
            font-size: $size-80;
            margin-bottom: 0.4rem;
            letter-spacing: -0.04;
        }
    }

    .detail-header{

        display: grid;
        grid-template-columns: 58% 1fr;
        gap: 3em;

        margin-top: 2em;
        margin-bottom: 1.4em;

        @media #{$media-mobile} {
            display: block;
        }

        h3{
            margin-bottom: 0.7em;

            @media #{$media-mobile} {
                font-size: $size-20f;
            }
        }

        .about{

            font-size: $size-40-h2-bis;
            line-height: 1.25;

            @media #{$media-mobile} {
                line-height: 1.18;
                margin-bottom: 2.1rem;
            }

        }

        .right-side{

            padding-left: 1.7em;
            border-width: 0 0 0 $border-3;
            border-style: solid;
            border-color: white;

            @media #{$media-mobile} {
                border-width: 2px 0 0 0;
                padding-left: 0;
            }

        }

        .team{
            font-size: $size-18f;
            line-height: 1.44;

            h3{
                @media #{$media-mobile} {
                    margin-top: 2.9rem;
                    font-size: $size-20f;
                }
            }
        }

    }

    .first-images{

        border-width: $border-3 0 0 0;
        border-style: solid;
        border-color: white;

        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        padding-top: 2em;
        gap: 1.6em;

        div {
            height: 30vw;
        }

        @media #{$media-mobile} {
            border-width: 2px 0 0 0;
            display: block;

            div {
                height: unset;
                position: relative; // make div square
                margin-bottom: 2*$pad;
            }

            div::before{
                content:'';
                display: block;
                padding-top: 100%;
            }
        }
    }

    .secondary-text{

        margin: 4em 25%;
        font-size: $size-25f;

        p{
            margin-bottom: 1em;
        }
  
        @media #{$media-mobile} {

            margin: 4rem 0;
            
            p{
                margin-bottom: 0;
            }
        }

    }

    .swiper{

        margin: 5em $pad;

        @media #{$media-mobile} {
            margin: 0 $pad 4em $pad;
        }

        .swiper-wrapper {

            height: 80vh;

            @media #{$media-mobile} {
                height: 50rem;
            }

            .swiper-slide {

                // height: 80vh;
    
                // @media #{$media-mobile} {
                //     height: 50rem;
                // }
    
                img{
                    margin: 0 auto;
                    display: block;
                    object-fit: contain;
                }
            }
        }


        .button-prev {
            left: 1.5em;
            right: auto;

            @media #{$media-mobile} {
                left: $pad;
            }
        }

        .button-next {
            left: auto;
            right: 1.5em;

            @media #{$media-mobile} {
                right: $pad;
            }
        }


        .button{
            position: absolute;
            top: 50%;

            margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));

            border-radius: 2em;
            width: 75px;
            display: grid;
            height: 58px;
            border: 3px solid white;
            align-items: center;
            justify-items: center;
            justify-content: center;
            padding: 0;

            z-index: 10;
            background-color: black;
            border-color: white;

            img{transition: filter 0.6s ease}

            @media #{$media-mobile} {
                height: 34px;
                width: 48px;
                border-width: 2px;

                img{
                    width: 80%;
                }
            }

        }

        .button:hover, .button:active, .button:focus {
            background-color: white;
            color: black;
            border-color: black;
            img {filter:invert(100%)}
        }
    }

    .last-images{

        .image-LC{

            display: block;
            width: 45%;
            margin: 2.5em auto;

            @media #{$media-mobile} {
                width: 100%;
            }

        }

        .lowest{

            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 2em;
            margin-bottom: 4em;

            img {
                width: 100%;
            }

            @media #{$media-mobile} {
                width: 100%;
                display: block;
                margin-bottom: 2em;

                img{
                    margin-bottom: 4rem;
                }
            }
            
        }

    } 

    .tags {

        @media #{$media-mobile} {
            margin-bottom: 5rem;
        }

        p{
            margin-bottom: 1em;
            font-size: $size-fixed-20;
            letter-spacing: -0.04em;
        }

        ul{
            @media #{$media-mobile} {
                display: flex;
                flex-wrap: wrap;
            }

            li{
                margin-right: 0.25em;
                margin-bottom: 0.5em;
                min-width: 7em;
                text-align: center;

                flex-grow: 1;
            }
        }

    }

    .section-title {
        border-width: 3px 0 3px 0;
        margin: 2.3em 0;
        padding-top: 0.5em;

        @media #{$media-mobile} {
            border-width: 2px 0 2px 0;
            margin: 2.3em 0 $pad 0;
        }
    }

    .section-title-exibitions {
        border-width: 3px 0 0 0;
    }

    .related {
        .section-title {
            border-width: 0 0 3px 0;
        }
    }
}