.button-full {
    display: block;
    cursor: pointer;
    // width: 100%;

    position: relative;
    
    * {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    border-width: $border-5;
    border-style: solid;
    border-radius: 2em;

    text-transform: uppercase;
    font-family: $numberplate;
    text-align: center;

    opacity: 0.5;
    transition: opacity 0.5s ease;
}

.button-full:hover, 
.button-full:active, 
.button-full:focus {
    opacity: 1;
    transition: opacity 0.5s ease;
}
