main.main-new {
    padding: 12.3rem 0;
}

main.main-new-hero {
    .post-hero-cover{
        .logos, .menu, .homepage-menu {
            display: none;
        }
    }
}

main.main-new.xtra-padding {
    padding: 19.3rem 0 3rem 0 ;

    @media #{$media-mobile} {
        padding: 16.2rem 0 3rem 0 ;
    }
}

.header-new{
    z-index: 11; //over mouseanimate
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    // @media #{$media-mobile} {
    //     min-height: 170px; // SAFARI ISSUES
    // }
    // transition: opacity 0.5s ease, background-color 0.5s ease;
    // transition: opacity 0.5s ease;

    .logos{
        height: $header;
        min-height: 2rem;
        display: grid;
        gap: 2vw;
        margin-bottom: $pad;
        grid-template-columns: auto 1fr auto ;

        transition: height $ani ease;
        transition: height $ani ease, margin-bottom $ani ease;
        transition: height $ani ease, margin-bottom $ani ease, gap $ani ease;

        @media #{$media-mobile} {
            height: unset;
            gap: 0;
            margin-bottom: 0;
        }

        .main, .secondary{
            height: inherit;
            min-height: 2rem;

            img{
                display: block;
                height: inherit;
                min-height: 2rem;
            }
        }

        .main, .main img{
            @media #{$media-mobile} {
                height: unset;
            }
        }

        .secondary{
            @media #{$media-mobile} {
                display: none;
            }
        }

        .text {
            margin: -31px 0 0 -8px;
            font-family: $numberplate;
            font-size: 144px;
            letter-spacing: -1px;
            span{
                margin-right: 0.145em;
            }
        }

        .active{
            -webkit-text-stroke-width: $border-3; 
            paint-order: stroke fill;
            color: rgba(0,0,0,0);
        }

    }

    .side {
        height: inherit;
        .button-side{
            height: inherit;
            // min-height: 4rem;
        }

        @media #{$media-mobile} {
            display: none;
        }

        .button-side{
            @media #{$media-tablet} {
                border-width: 2px;
            }
        }
    }


    .side.reduced {
        align-self: center;
        height: unset;

        .button-side{
            font-size: $size-button-side;
            width: 100%;

            align-self: center;
    
            height: unset;
            min-height: unset;

            padding: 0.2em 1em;
    
            writing-mode: inherit;
            text-orientation: inherit;
        }
    }

    .animated-cross-menu{
        display: none;

        align-self: center;
        margin-left: 30px;

        @media #{$media-mobile} {
            display: block;
        }
    }

    .homepage-menu{
        display: none;

        @media #{$media-mobile} {
            display: grid;

            .homepage-selectors {

                height: unset;

                li{
                    height: 71px;

                    a {
                        top: unset;
                        transform: unset;
                        
                        .menu-item{
                            height: 100%;
                            border-left-width: 0;
                            border-right-width: 0;
                        }  
                    }
                }
            }
        }
    }
}


// science section particularities
.science-universe {
    .header-new {
        .button-side{
            padding: 0.1em; /***correct***/
        }
    }
}



header.white-color {
    color: white;
    border-color: white;
    background-color: black;

    .logos img{
        filter: brightness(0) saturate(100%) invert(100%);
    }

    .logos .active {
        color: rgba(0,0,0,0);
        -webkit-text-stroke-color: #ffffff; 
    }

    .side{
        .button-side:hover, 
        .button-side:focus, 
        .button-side:active {
            background-color: white;
            color: black;
            border-color: white;
        }
    }

    nav.menu {

        ul{
            li.normal-item{
                a:hover, a:active, a:focus {
                    -webkit-text-stroke-color: white; 
                }
            }
    
            li.normal-item a,
            li.search-item a{
                -webkit-text-stroke-color: white; 
            }
        
            li.search-item {
                div.hover {
                    -webkit-text-stroke-color: black; 
                }
                img{
                    filter: none;
                }
            }
            li.active{
                a {
                    -webkit-text-stroke-color: white;
                    color: transparent;
                }
            }
        }
    }

    @media #{$media-mobile} {
        nav.menu-about {
            background-color: black;
            color: white;
            .mobile .arrow{
                filter: none;
            }
            .border{
                border-color: white;
            }
        }
    }
}


.science-universe header.header-new.white-color .homepage-menu .homepage-selectors {
    @media #{$media-mobile} {
        li:first-of-type {
            background-color: white;
            padding: 0;
            color: black;
            border-color: white;
            a .menu-item h1 {
                opacity: 1;
            }      
        }
    }
}


header.black-color {
    color: black;
    border-color: black;
    background-color: white;

    .logos img{
        filter: none;
    }

    // .logos .active {
    //     color: rgba(0,0,0,0);
    //     -webkit-text-stroke-color: black;
    // }

    .side{
        .button-side:hover, 
        .button-side:focus, 
        .button-side:active {
            background-color: black;
            color: white;
            border-color: black;
        }
    }

    .animated-cross-menu{
        filter: invert(100%);
    }


    nav.menu {
        ul {
            li.normal-item{
                a:hover, a:active, a:focus {
                    -webkit-text-stroke-color: black;
                }
            }
        
            li.normal-item a, 
            li.search-item a {
                -webkit-text-stroke-color: black; 
            }
        
            li.search-item {
        
                div.hover {
                    -webkit-text-stroke-color: #ffffff;
                }
        
                img{
                    filter: invert(100%);
                }
            }
        
            li.active{
                a {
                    -webkit-text-stroke-color: black;
                }
            }
        }
    }
    @media #{$media-mobile} {
        nav.menu-about {
            background-color: white;
            color: black;
            .mobile .arrow{
                filter: invert(1);
            }
            .border{
                border-color: black;
            }
        }
    }
}

.art-universe header.header-new.white-color .homepage-menu .homepage-selectors {
    @media #{$media-mobile} {
        li:last-of-type {
            background-color: white;
            padding: 0;
            color: black;
            border-color: white;
            a .menu-item h1 {
                opacity: 1;
            }      
        }
    }
}

.art-universe header.header-new.black-color .homepage-menu .homepage-selectors {
    @media #{$media-mobile} {

        li {
            color: black;
        }
        li:last-of-type {
            background-color: black;
            padding: 0;
            color: white;
            border-color: black;
            a .menu-item h1 {
                opacity: 1;
            }      
        }
    }
}

header.header-new.no-bg {
    background-color: transparent;
}