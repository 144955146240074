// Media queries
$media-desktop: "only screen and (max-width : 1024px)";
$media-tablet: "only screen and (max-width : 990px)";
$media-mobile: "only screen and (max-width : 767px)";
$media-mobile-sm: "only screen and (max-width : 375px)";

$media-mobile-H: "only screen and  (max-height: 768px)";


// Colors


// Fonts
$abc: 'ABC Whyte Inktrap', 'Montserrat', sans-serif;
$numberplate: 'Numberplate Luxembourg', 'Andale Mono', sans-serif;

// Font-sizes
//$size-h1: clamp(50px, 4vw, 70px);  //70*100vw/1680
//$size-h2: clamp(25px, 2.4vw, 40px); //40*100vw/1680

$size-homepage-text: clamp(30px, 3vw, 60px); //grows slower
// $size-button-side: clamp(21px, 2vw, 33px); // grows faster
$size-button-side: clamp(12px, 1.65vw, 20px); // grows faster
$size-20f: clamp(16px, 1.85vw, 20px);  // grows faster
$size-25f:  clamp(16px, 2.5vw, 25px);  // grows faster
$size-18f: clamp(14px, 1.8vw, 18px);  // grows faster

$size-45-55: clamp(45px, 4.125vw, 55px); // titles of covers, big minimum
$size-fixed-20: 20px; // fixed (buttons, years)
$size-70-h1: clamp(38px, 5.25vw, 70px); // minimum higher
$size-70-h2: clamp(45px, 5.25vw, 70px); // minimum much higher
$size-70-h3: clamp(31px, 5.25vw, 70px); // minimum a little bit higher
$size-40-h2: clamp(27px, 3vw, 40px); // minimum higher
$size-40-h2-bis: clamp(22px, 3vw, 40px); // minimum higher
$size-65-38: clamp(38px, 4.9vw, 65px);
$size-50-31: clamp(31px, 3.75vw, 50px);
$size-35-22: clamp(22px, 2.625vw, 35px); // min higher
$size-35-27: clamp(27px, 2.625vw, 35px); // min much higher

$size-root: clamp(10px, 1.5vw, 20px); 


$size-80: 4rem; 
$size-70: 3.5rem; 
$size-65: 3.25rem;
$size-50: 2.5rem; 
$size-40: 2rem;

$size-30: 1.5rem; 


// border sizes
// $border-5: clamp(2px, 0.4vw, 5px); 
// $border-3: clamp(1px, 0.3vw, 3px); 
// $border-2: clamp(1px, 0.2vw, 2px); 
$border-5: 5px; 
$border-3: 3px; 
$border-2: 2px; 

// Spacing
$pad: 1.4rem;
$pad-small: 0.85rem;
$pad-half: 0.7rem;
$pad-smaller: 0.5rem;



// Spacing - vertical
$header: calc((53vw)*(225/1620));   /* header logo orig dim 1620x225 */
$header-homepage: calc((100vw - 10px - 3*#{$pad})*(225/1620)); //scrollbar
$homepage-selectors-height: 1.65em;

// animations
// $ani: 0.2s;
$ani: 0s;