// CROSS CLOSE -- IFRAME
.animated-cross {
    position: relative;
    height: 1.85rem;  /* this can be anything */
    width: 1.85rem; /* ...but maintain 1:1 aspect ratio */
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media #{$media-tablet} {
        height: 26px; 
        width: 26px; 
    }
}

.animated-cross::before,
.animated-cross::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 3px; /* cross thickness */
    background-color: white;
    transition: transform 0.5s ease;

    @media #{$media-tablet} {
        height: 2.3px;
    }
    @media #{$media-mobile} {
        height: 2px;
    }
}

.animated-cross::before {
    transform: rotate(90deg);
}

.animated-cross.active::before {
    transform: rotate(45deg);
}

.animated-cross.active::after {
    transform: rotate(-45deg);
}

.animated-cross-display {
    display: none;
}


// CROSS CLOSE -- SEARCH

.animated-cross-search {
    position: relative;
    height: 0.9em;
    width: 0.9em;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media #{$media-mobile} {
        height: 28.3px;
        width: 28.3px;
    }
}

.animated-cross-search::before,
.animated-cross-search::after {
    position: absolute;
    content: '';
    top: 40%;
    width: 100%;
    height: 3px; /* cross thickness */
    background-color: white;
    transition: transform 0.5s ease;

    @media #{$media-mobile} {
        top: 6%;
    }
}

.animated-cross-search::before {
    transform: rotate(45deg);
}

.animated-cross-search::after {
    transform: rotate(-45deg);
}

.art-universe, 
.home-universe {
    .animated-cross-search::before,
    .animated-cross-search::after {
        background-color: black;
    }
}


// CROSS CLOSE -- MOBILE MENU

.animated-cross-menu, .animated-cross-menu-inner {
    position: relative;
    height: 26px;  /* this can be anything */
    width: 26px; /* ...but maintain 1:1 aspect ratio */
    display: flex;
    flex-direction: column;
    justify-content: center;

    cursor: pointer;
}

.animated-cross-menu::before,
.animated-cross-menu-inner::before,
.animated-cross-menu::after,
.animated-cross-menu-inner::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px; /* cross thickness */
    background-color: white;
    transition: transform 0.5s ease;

    top: 50%;
}

.animated-cross-menu::before,
.animated-cross-menu-inner::before {
    transform: rotate(90deg);
}

.menu-main.show .animated-cross-menu::before,
.menu-main.show .animated-cross-menu-inner::before {
    transform: rotate(45deg);
}

.menu-main.show .animated-cross-menu::after,
.menu-main.show .animated-cross-menu-inner::after {
    transform: rotate(-45deg);
}