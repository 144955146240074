.template-publications{

    padding-bottom: 2.5em;

    margin-top: -$pad;

    .publication-item {

        padding: $pad 0;
        margin-left: 1.4rem;
        margin-right: 1.4rem;

        border-width: 0 0 $border-3 0;
        border-color: white;
        border-style: solid;

        @media #{$media-tablet} {
            border-width: 0 0 2px 0;
        }

        @media #{$media-mobile} {
            padding: 1.4rem 0 2rem 0;
        }

        .listing{

            display: grid;
            grid-template-columns: 20.5% 1fr;
            gap: $pad;

            @media #{$media-mobile} {
                display: block;
            }

            .image img {
                @media #{$media-mobile} {
                    // height: 231px;
                    // object-fit: cover;
                    height: auto;
                    object-fit: contain;
                    width: 100%;
                }
            }

            .text {
                h2{
                    font-size: $size-65;
                    line-height: 1; 
                    margin-bottom: 0.14em;

                    @media #{$media-mobile} {
                        font-size: 27px;
                        line-height: 25.6px; 
                        letter-spacing: -0.02em;
                        margin: 0.5em 0;
                    }
                }

                h3{
                    font-size: $size-20f;
                    line-height: 1.354; 
                    margin-bottom: 1em;

                    @media #{$media-mobile} {
                        margin-bottom: 0.6em;
                    }
                }

                p {
                    margin-bottom: 1em;
                }

                .buttons{

                    margin-top: 2.4rem;
                    margin-bottom: -0.5rem; // compensate buttons margin

                    @media #{$media-mobile} {
                        margin-top: 3.3rem;
                    }

                    .button{
                        height: 2.5em;
                        // line-height: 2.5em;
                        line-height: calc(2.5em - 3px); // font not vertically centered
                        position: relative;
                        margin-right: 0.2em;
                        margin-bottom: 0.5rem;

                        min-width: 171px;
                        padding: 0 3.5em;

                        display: inline-grid;
                        grid-template-columns: auto auto;
                        align-items: center;

                        @media #{$media-mobile} {
                            padding: 0 1.4rem;
                            min-width: unset;
                        }

                        * {
                            display: block;
                        }

                        img{
                            position: absolute;
                            right: 0.6em;
                            width: 0.66em;
                            top: 50%;
                            transform: translateY(-50%);
                            padding-top: 0.05em;
                            transition: filter 0.5s ease;

                            @media #{$media-mobile} {
                                position: relative;
                                width: 0.66em;
                                top: unset;     
                                transform: none;
                                right: 0;     
                                padding-top: 0;
                                margin-left: 0.3em;   
                            }
                        }

                        a:focus, a:active {
                            img {
                                filter: invert(100%);
                                transition: filter 0 ease;
                            }
                        }

                    }

                    .button:hover {
                        img {
                            filter: invert(100%);
                            transition: filter 0 ease;
                        }
                    }

                    .abstract-btn{
                        display: inline-block;
                        @media #{$media-mobile} {
                            min-width: 113px;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .abstract{
            display: none;
            margin-bottom: 1em;
            font-size: $size-40;

            div{
                margin-top: 2.4rem;
            }

            p{
                margin-top: 0.7em;
            }

            p:first-of-type{
                margin-top: 0;
            }
        }
    }

    // placement of loader

    position: relative;

    #publications-list{
        background-color: black;
        position: relative;
        z-index: 1;
    }

    #pubs-loader{
        text-align: center;
        margin: 2*$pad auto 0 auto; 
        font-size: $size-45-55;
        font-size: 6rem;
        font-size: $size-65-38;
        opacity: 0.6;

        z-index: 0;
        position: fixed;
        top: 320px;
        width: 100%;
        display: block;

        // source https://codepen.io/alvarotrigo

        text-transform: uppercase;
        // background-image: linear-gradient(
        //     -225deg,
        //     #231557 0%,
        //     #44107a 29%,
        //     #ff1361 67%,
        //     #fff800 100%
        // );
        background-image: linear-gradient(-225deg, #000000 0%, #733bad 29%, #e984a5 67%, #ffffff 100%);
        filter: grayscale(1);
        background-size: auto auto;
        background-clip: border-box;
        background-size: 200% auto;
        color: #fff;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: textclip 3s linear infinite;

        @media #{$media-tablet} {
            top: 240px;
        }

        @media #{$media-mobile} {
            animation: textclip 2s linear infinite;
            top: 170px;
        }
    }

    @keyframes textclip {
        to {
            background-position: 200% center;
        }
    }
}