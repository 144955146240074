.fancybox__container {
    .fancybox__thumbs {
        .carousel__slide {

            opacity: 0.4;

            .fancybox__thumb{
                border-radius: 0;
            }
            .fancybox__thumb::after {
                display: none;
            }
        }

        .carousel__slide.has-thumb.is-nav-selected {
            opacity: 1;
        }
    }

    .carousel__button.is-close {
        top: 30px;
        right: 30px;
    }

    .fancybox__caption{
        text-align: center;
        font-size: 0.9em;

        // p{
        //     font-size: 0.9em;
        // }
    }

    .fancybox__nav {
        --carousel-button-svg-width: 0;
        --carousel-button-svg-height: 0;
        --carousel-button-svg-stroke-width: 0;

        .carousel__button {
            color: white;
            background-color: black;
            border: 3px solid white;
            border-radius: 2em;
            width: 75px;
            height: 58px;
            background-size: 46%;
            background-repeat: no-repeat;
            background-position: center;
          
        // .carousel__button.is-prev::after,
        // .carousel__button.is-next::after {
        //     content:'';
        //     display: block;
        //     position: absolute;
        //     color: white;
        //     background-color: black;
        //     border: 3px solid white;
        //     border-radius: 2em;
        //     width: 75px;
        //     height: 58px;
        //     background-size: 46%;
        //     background-repeat: no-repeat;
        //     background-position: center;

            @media #{$media-mobile} {
                height: 34px;
                width: 48px;
                border-width: 2px;
            }

            transition: filter 0.3s ease;

        }

        .carousel__button.is-prev {
            background-image: url('../img/arrow-left-white.svg');
            left: 1.5em;
            @media #{$media-mobile} {
                left: $pad;
            }
        }
        .carousel__button.is-next {
            background-image: url('../img/arrow-right-white.svg');
            right: 1.5em;
            @media #{$media-mobile} {
                right: $pad;
            }
        }

        .carousel__button:hover, .carousel__button:active, .carousel__button:focus {
            // background-color: white;
            // color: black;
            // border-color: black;
            // img {filter:invert(100%)}
            filter:invert(100%);
        }
    }
}