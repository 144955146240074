@font-face {
    font-family: 'ABC Whyte Inktrap';
    src: url('../fonts/fac715db9301c35ea36e21ebaf03fc71.eot');
    src: url('../fonts/fac715db9301c35ea36e21ebaf03fc71.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fac715db9301c35ea36e21ebaf03fc71.woff2') format('woff2'),
        url('../fonts/fac715db9301c35ea36e21ebaf03fc71.woff') format('woff'),
        url('../fonts/fac715db9301c35ea36e21ebaf03fc71.ttf') format('truetype'),
        url('../fonts/fac715db9301c35ea36e21ebaf03fc71.svg#.') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Numberplate Luxembourg';
    src: url('../fonts/b8de6912117494d2c3dee064ce5c4cf9.eot');
    src: url('../fonts/b8de6912117494d2c3dee064ce5c4cf9.eot?#iefix') format('embedded-opentype'),
        url('../fonts/b8de6912117494d2c3dee064ce5c4cf9.woff2') format('woff2'),
        url('../fonts/b8de6912117494d2c3dee064ce5c4cf9.woff') format('woff'),
        url('../fonts/b8de6912117494d2c3dee064ce5c4cf9.ttf') format('truetype'),
        url('../fonts/b8de6912117494d2c3dee064ce5c4cf9.svg#.') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

