#search-results, .template-science-detail #related-section {

    opacity: 0;
    transition: opacity 0.5s ease;

    h1{
        text-align: center;
        margin-top: 2*$pad;
        font-size: $size-65-38;
    }

    h2{
        text-align: center;
        margin: 2*$pad auto 0 auto; 
        font-size: $size-45-55;
        font-size: 6rem;
        font-size: $size-65-38;
        opacity: 0.6;

        // source https://codepen.io/alvarotrigo

        text-transform: uppercase;
        // background-image: linear-gradient(
        //     -225deg,
        //     #231557 0%,
        //     #44107a 29%,
        //     #ff1361 67%,
        //     #fff800 100%
        // );
        background-image: linear-gradient(-225deg, #000000 0%, #733bad 29%, #e984a5 67%, #ffffff 100%);
        filter: grayscale(1);
        background-size: auto auto;
        background-clip: border-box;
        background-size: 200% auto;
        color: #fff;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: textclip 3s linear infinite;

        @media #{$media-mobile} {
            animation: textclip 2s linear infinite;
        }

        display: block;
        
    }

        @keyframes textclip {
            to {
                background-position: 200% center;
            }
        }

    .default-grid{

        margin: 2*$pad 0;
        opacity: 0;
        transition: opacity 0.5s ease;

        @media #{$media-mobile} {
            margin: 2.5rem 0;                
            display: block;
        }

        .type{
            font-size: $size-20f;
            margin: 0.7em 0 0.5em 0;

            @media #{$media-mobile} {
                margin-bottom: 0.4em;
            }
        }
    
        .title{
            font-size: $size-50-31;
            line-height: 1.08;
            margin-bottom: 4rem;
            letter-spacing: -0.04em;

            @media #{$media-mobile} {
                margin-bottom: 2rem;
            }
        }
    
        .publication_buttons{
    
            margin-top: -3rem;
            margin-bottom: 4rem;

            @media #{$media-mobile} {
                margin: 2rem 0;
            }
    
            li{
    
                margin-bottom: 0.4rem;
                position: relative;
    
                font-size: $size-20f;

                @media #{$media-mobile} {
                    padding: 0 1.9rem;
                    margin-right: 0.5rem;
                }

                img{
                    position: relative;
                    top: 0.1rem;
    
                    margin-left: 0.3rem;
                    width: 0.7em;
    
                    transition: filter 0.5s ease;

                    @media #{$media-mobile} {
                        display: none;
                    }
                }
            }
    
            a:hover, a:focus, a:active {
                img {
                    filter: invert(100%);
                    transition: filter 0 ease;
                }
            }
        }
    }

}

.science-universe #search-results {
    background-color: black;
}

.art-universe #search-results, 
.home-universe #search-results {

    background-color: white;

    .publication_buttons{

        li {
            img{
                filter: invert(100%);
            }
        }
        a:hover, a:focus, a:active {
            img {
                filter: none;
            }
        }
    }
}
